.footer {
  background-color: #222D39;
  color: #ADB7C2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10%;
  font-size: 14px;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
  gap: 15px;
}

.social-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-icons a {
  color: #ADB7C2;
  font-size: 22px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons a:hover {
  color: #fff;
  transform: scale(1.2);
}

.footer-text {
  flex: 1;
  text-align: center;
  color: #ADB7C2;
  font-size: 14px;
  line-height: 1.5;
}

/* Media Queries for Smaller Screens */
@media (max-width: 1024px) {
  .footer {
    padding: 20px 5%;
  }

  .social-icons a {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    padding: 15px;
    gap: 10px;
  }

  .footer-text {
    font-size: 13px;
    margin-top: 10px;
  }

  .social-icons a {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 15px 10px;
  }

  .social-icons a {
    font-size: 16px;
  }

  .footer-text {
    font-size: 12px;
    line-height: 1.3;
  }
}
