*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}
  
body{
    background: #fffaf1;
}