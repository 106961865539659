.project-page {
  padding: 20px;
  text-align: center;
  background-color: #fffaf1;
}

.project-page h1 {
  color: var(--noel-color);
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 0 10px;
}

.project-card {
  background-color: #fff;
  border: 2px solid var(--tree-color);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.project-card img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.project-card img:hover {
  transform: scale(1.03);
}

.project-card h2 {
  font-size: 1.5rem;
  color: var(--other-color);
  margin-bottom: 15px;
  font-weight: 600;
}

.project-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.project-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  color: #fff;
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.github-btn {
  background-color: #333;
}

.github-btn:hover {
  background-color: #555;
}

.live-btn {
  background-color: var(--noel-color);
}

.live-btn:hover {
  background-color: #ff5f72;
}

@media (max-width: 1024px) {
  .project-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }

  .project-card {
    max-width: 90%;
    padding: 15px;
  }

  .project-page h1 {
    font-size: 2.2rem;
  }

  .project-card h2 {
    font-size: 1.3rem;
  }

  .project-links a {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

@media (max-width: 768px) {
  .project-grid {
    grid-template-columns: 1fr;
    padding: 0 5px;
  }

  .project-card {
    max-width: 95%;
  }

  .project-page h1 {
    font-size: 2rem;
  }

  .project-card h2 {
    font-size: 1.2rem;
  }

  .project-links a {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  .project-card {
    padding: 10px;
  }

  .project-page h1 {
    font-size: 1.8rem;
  }

  .project-card h2 {
    font-size: 1rem;
  }

  .project-links a {
    font-size: 0.75rem;
    padding: 5px 8px;
  }
}