@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
:root {
    --other-color: #653818;
    --noel-color: #ec3750;
    --tree-color: #33d6a6;
}


body {
    font-family: 'Poppins', sans-serif;
}
