.faq-section {
    padding: 50px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .faq-title {
    font-size: 2rem;
    color: #222D39;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .faq-container {
    max-width: 800px;
    width: 100%;
  }
  
  .faq-item {
    background-color: #fff;
    padding: 20px;
    margin: 15px 0;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .faq-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .faq-question {
    font-size: 1.2rem;
    color: var(--other-color);
    margin-bottom: 10px;
  }
  
  .faq-answer {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  @media (max-width: 600px) {
    .faq-title {
      font-size: 2rem;
    }
  
    .faq-question {
      font-size: 1.3rem;
    }
  
    .faq-answer {
      font-size: 0.9rem;
    }
  }
  