.schedule-section {
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    background-color: #F7F9FC;
  }
  
  .schedule-title {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #222D39;
    text-align: center;
  }
  
  .coming-soon {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .coming-soon h2 {
    font-size: 1.2rem;
    color: var(--other-color);
    margin-bottom: 15px;
  }
  
  .coming-soon p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .schedule-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
    max-width: 900px;
  }
  
  .schedule-item {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .schedule-item:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
  
  .schedule-icon {
    font-size: 1.5rem;
    color: #ec3750;
    margin-right: 15px;
  }
  
  .schedule-details {
    display: flex;
    flex-direction: column;
  }
  
  .schedule-time {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .schedule-description {
    font-size: 1rem;
    color: #555;
  }
  
 
  @media (max-width: 768px) {
    .schedule-title {
      font-size: 1.5rem;
    }
  
    .coming-soon h2 {
      font-size: 1.7rem;
    }
  
    .coming-soon p {
      font-size: 1rem;
    }
  
    .schedule-container {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  
    .schedule-item {
      padding: 15px;
    }
  
    .schedule-icon {
      font-size: 1.3rem;
      margin-right: 10px;
    }
  
    .schedule-time {
      font-size: 1rem;
    }
  
    .schedule-description {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .schedule-title {
      font-size: 1.8rem;
    }
  
    .coming-soon h2 {
      font-size: 1.8rem;
    }
  
    .schedule-container {
      grid-template-columns: 1fr 1fr;
    }
  
    .schedule-item {
      padding: 18px;
    }
  
    .schedule-icon {
      font-size: 1.4rem;
    }
  
    .schedule-time {
      font-size: 1.1rem;
    }
  
    .schedule-description {
      font-size: 1rem;
    }
  }
  
  