.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
  color: #653818;
  text-align: center;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.hero-title {
  font-size: 3rem;
  margin: 0;
  color: #e63946;
}

.santa-animation {
  width: 180px;
  margin: 20px 0;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.hero-info {
  margin: 15px 0;
  max-width: 90%;
}

.hero-paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 10px 0;
  text-align: center;
}

.location-icon {
  margin-right: 8px;
  color: var(--noel-color);
  font-size: 1.3rem;
}

.hero-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

.hero-btn {
  display: inline-block;
  padding: 12px 25px;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join-btn {
  background-color: #e63946;
}

.apply-btn {
  background-color: transparent;
  color: #e63946;
  border: 2px solid #e63946;
}

.hero-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.hero-btn:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .santa-animation {
    width: 140px;
  }

  .hero-paragraph {
    font-size: 0.9rem;
  }

  .hero-btn {
    padding: 10px 20px;
    font-size: 0.8rem;
  }
}

.audio-control {
  position: fixed;
  bottom: 20px;
  right: 15px;
  background: #fff;
  border: 2px solid #e63946;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.audio-control:hover {
  transform: scale(1.1);
}

.audio-icon {
  color: #e63946;
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .audio-control {
    bottom: 10px;
    right: 10px;
    padding: 6px;
  }

  .audio-icon {
    font-size: 1.4rem;
  }
}
